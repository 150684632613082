.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    transform-origin: 50% 10%;
    animation: App-logo-spin infinite 4s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
.App-header2 {
  margin-top: -5px;
  background-color: #670011;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.banner {
  width: 100%;
}
.fbCon {
  width: 50%;
  max-width: 200px;
}
.fbCon img {
  width: 100%;
}
@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
