.navBar {
  width: 100%;
  display: flex;
  padding: 15px 0;
  background: #fff;
  justify-content: space-around;
  box-shadow: 0 4px 4px #0002;
  position: relative;
  transition: top 0.3s ease-in-out;
  z-index: 100000;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

/* for logo */

.navBar p {
  margin-left: 10px;
}
.LogoArea img {
  width: 45px;
}
.LogoArea {
  display: flex;
  font-size: 20px;
  font-family: Arial;
  font-weight: bolder;
  color: #2906ff;
  opacity: 0.5;
  order: 1;
}
/* For search */
.SearchArea {
  display: flex;
  order: 2;
}
.SearchArea input {
  width: 100%;
  height: 95%;
  padding: 0 10px;
  border: 2px solid #414141;
  border-left: none;
  outline: none;
  font-size: 15px;
  color: #919191;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.SearchArea ::placeholder {
  color: rgb(177, 177, 177); /* Change the color to your desired value */
}
.SearchArea button {
  height: 95%;
  padding: 0 10px;
  border: 2px solid #414141;
  border-right: none;
  outline: none;
  padding-top: 3px;
  padding-left: 13px;
  font-size: 20px;
  color: #919191;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background: #fff;
}

.button_collection {
  display: flex;
  color: #414141;
  order: 3;
  font-size: 30px;
}
.button_collection .btnitems {
  padding: 3px;
  margin-left: 20px;
  position: relative;
}
.btnR1 {
  font-size: 25px;
  margin-top: 3px;
}
.bubble {
  background: #f00;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 100%;
  position: absolute;
  color: #fff;
  margin-left: 20px;
  margin-top: 15px;
}
.bubble2 {
  background: #f00;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 100%;
  position: absolute;
  color: #fff;
  margin-left: 25px;
  margin-top: 18px;
}
@media screen and (min-width: 1001px) {
  .SearchArea input {
    width: 100%;
    min-width: 500px;
  }
}
@media screen and (max-width: 750px) {
  .navBar {
    display: block;
    width: 96%;
    padding: 2%;
  }
  .LogoArea {
    font-size: 12px;
    float: left;
    padding: 5px;
    width: 40%;
  }
  .LogoArea img {
    width: 30px;
    height: 30px;
  }
  .SearchArea {
    width: 96%;
    padding: 2%;
  }
  .SearchArea input {
    padding: 12px;
  }
  .SearchArea button {
    padding: 9.1px;
  }
  .button_collection {
    float: right;
    font-size: 24px;
    padding-top: 5px;
  }
  .hideV {
    display: none;
  }
}
