.HomeTopComp {
  margin-top: 10px;
  display: flex;
  width: 97%;
  padding: 10px 0;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;
}
.HomeTopLeft h1 {
  font-size: 70px;
  padding: 15px;
  width: 100px;
}
.HomeTopLeft p {
  font-size: 25px;
  padding: 15px;
  font-weight: 500;
  color: #353535;
}
.HomeTopLeft {
  width: 50%;
  order: 1;
  position: relative;
}
.HomeTopRight {
  width: 50%;
  position: relative;
  order: 2;
}
.Triangle {
  padding: 40px 0;
  width: 500px;
}
.imageB1 {
  position: absolute;
  height: 400px;
  filter: drop-shadow(-4px 4px 6px rgba(0, 0, 0, 0.897));
  animation: boxPopup2 1.6s ease-in-out forwards;
}
.imageB2 {
  position: absolute;
  height: 400px;
  filter: drop-shadow(-4px 4px 6px rgba(0, 0, 0, 0.897));
  animation: boxPopup 1.6s ease-in-out forwards;
}
@keyframes boxPopup {
  0% {
    /* opacity: 0; */
    transform: rotate(0) translate(-90, 0) scale(0.1);
  }
  50% {
    /* opacity: 0; */
  }
  100% {
    opacity: 1;
    transform: rotate(5deg) translate(190px, 40px) scale(1);
  }
}
@keyframes boxPopup2 {
  0% {
    /* opacity: 0; */
    transform: rotate(0) translate(-90, 0) scale(0.1);
  }
  50% {
    /* opacity: 0; */
  }
  100% {
    opacity: 1;
    transform: rotate(-35deg) translate(30px, 0) scale(1);
  }
}
.randomShape {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -100;
}
.shape1 {
  position: absolute;
  width: 250px;
  height: 250px;
  background: rgba(255, 145, 0, 0.247);
  top: 30%;
  border-radius: 100%;
  margin-left: -40px;
}
.shape2 {
  position: absolute;
  width: 150px;
  height: 150px;
  background: rgba(255, 0, 0, 0.247);
  top: 50%;
  left: 70%;
  border-radius: 100%;
  margin-left: -40px;
}
.shape3 {
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 255, 0.3);
  top: 10%;
  left: 60%;
  border-radius: 100%;
  margin-left: -40px;
}

@media screen and (max-width: 1100px) {
  .Triangle {
    padding: 40px 0;
    width: 400px;
  }
  .imageB1 {
    position: absolute;
    height: 300px;
    animation: boxPopup 1.6s ease-in-out forwards;
  }
  .imageB2 {
    position: absolute;
    height: 300px;
    animation: boxPopup2 1.6s ease-in-out forwards;
  }
  @keyframes boxPopup {
    0% {
      /* opacity: 0; */
      transform: rotate(0) translate(-90, 0) scale(0.1);
    }
    50% {
      /* opacity: 0; */
    }
    100% {
      opacity: 1;
      transform: rotate(-35deg) translate(10px, 0) scale(1);
    }
  }
  @keyframes boxPopup2 {
    0% {
      /* opacity: 0; */
      transform: rotate(0) translate(-90, 0) scale(0.1);
    }
    50% {
      /* opacity: 0; */
    }
    100% {
      opacity: 1;
      transform: rotate(5deg) translate(150px, 40px) scale(1);
    }
  }
}
@media screen and (max-width: 850px) {
  .Triangle {
    padding: 50px 0;
    width: 350px;
  }
  .imageB1 {
    position: absolute;
    height: 290px;
    filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.466));
    animation: boxPopup 1.6s ease-in-out forwards;
  }
  .imageB2 {
    position: absolute;
    height: 290px;

    filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.466));
    animation: boxPopup2 1.6s ease-in-out forwards;
  }
  @keyframes boxPopup {
    0% {
      /* opacity: 0; */
      transform: rotate(0) translate(-90, 0) scale(0.1);
    }
    50% {
      /* opacity: 0; */
    }
    100% {
      opacity: 1;
      transform: rotate(-25deg) translate(-20px, 15px) scale(1);
    }
  }
  @keyframes boxPopup2 {
    0% {
      /* opacity: 0; */
      transform: rotate(0) translate(-90, 0) scale(0.1);
    }
    50% {
      /* opacity: 0; */
    }
    100% {
      opacity: 1;
      transform: rotate(5deg) translate(90px, 40px) scale(1);
    }
  }
}
@media screen and (max-width: 750px) {
  .HomeTopLeft h1 {
    font-size: 30px;
    padding: 15px;
    width: 100%;
  }
  .HomeTopComp {
    margin-top: 10px;
    display: block;
    width: 96%;
    padding: 10px 2%;
  }

  .HomeTopLeft p {
    font-size: 15px;
    padding: 15px;
    font-weight: 500;
  }
  .shape1 {
    position: absolute;
    width: 150px;
    height: 150px;
    background: rgba(255, 145, 0, 0.247);
    top: 30%;
    border-radius: 100%;
    margin-left: -40px;
  }
  .shape2 {
    position: absolute;
    width: 75px;
    height: 75px;
    background: rgba(255, 0, 0, 0.247);
    top: 50%;
    left: 90%;
    border-radius: 100%;
    margin-left: -40px;
  }
  .shape3 {
    position: absolute;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 255, 0.3);
    top: 10%;
    left: 60%;
    border-radius: 100%;
    margin-left: -40px;
  }
  .HomeTopLeft {
    width: 100%;
  }
  .HomeTopRight {
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 450px) {
  .Triangle {
    padding: 10px 0;
    width: 220px;
  }
  .imageB1 {
    position: absolute;
    height: 150px;
    animation: boxPopup 1.6s ease-in-out forwards;
  }
  .imageB2 {
    position: absolute;
    height: 150px;
    animation: boxPopup2 1.6s ease-in-out forwards;
  }
  @keyframes boxPopup {
    0% {
      /* opacity: 0; */
      transform: rotate(0) translate(-90, 0) scale(0.1);
    }
    50% {
      /* opacity: 0; */
    }
    100% {
      opacity: 1;
      transform: rotate(-25deg) translate(-30px, -10px) scale(1);
    }
  }
  @keyframes boxPopup2 {
    0% {
      /* opacity: 0; */
      transform: rotate(0) translate(-90, 0) scale(0.1);
    }
    50% {
      /* opacity: 0; */
    }
    100% {
      opacity: 1;
      transform: rotate(5deg) translate(50px, 10px) scale(1);
    }
  }
}
