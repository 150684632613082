* {
  padding: 0;
  margin: 0;
  font-family: Arial;
}
body {
  background: #eee;
}
.container {
  height: 100vh;
  width: 100vw;
}

.load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width: 100px;
  height: 100px;
}
.load hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}

.load :first-child {
  background: #19a68c;
  animation-delay: -1.5s;
}
.load :nth-child(2) {
  background: #f2b3b3;
  animation-delay: -1s;
}
.load :nth-child(3) {
  background: #f2d7b3;
  animation-delay: -0.5s;
}
.load :last-child {
  background: #a6a6f3;
}

@keyframes spin {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(160%);
  }
  50% {
    transform: translate(160%, 160%);
  }
  75% {
    transform: translate(0, 160%);
  }
}
